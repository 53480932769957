import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import env from "../config/enviroment";
import {
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Divider,
  CircularProgress,
  Link,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Chip,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUser } from "../services/UserContext";
import { admin } from "../config/levelGroups";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LinkIcon from "@mui/icons-material/Link";

function UserInformation() {
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState("customerId");
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser();

  const searchEndpoints = {
    customerId: `${env.API_URL}/customerService/informacionUsuario`,
    phone: `${env.API_URL}/customer/phone`,
    email: `${env.API_URL}/customer/email`,
    deviceId: `${env.API_URL}/customer/deviceId`,
    intelligentialClientId: `${env.API_URL}/customer/intelligentialClientId`,
  };

  const fetchUserInfo = async () => {
    setLoading(true);
    try {
      let customerId;

      if (searchType !== "customerId") {
        const initialResponse = await axios.get(
          `${searchEndpoints[searchType]}/${searchValue}`,
          {
            headers: {
              "api-key": env.API_KEY_FABISAN,
            },
          }
        );
        customerId = initialResponse.data.id;
        if (!customerId) {
          throw new Error("No id found in initial response");
        }
      } else {
        customerId = searchValue;
      }

      const response = await axios.get(
        `${env.API_URL}/customerService/informacionUsuario/${customerId}`,
        {
          headers: {
            "api-key": env.API_KEY_MIDDLEWARE,
          },
        }
      );
      setUserInfo(response.data);
      setSearchValue("");
    } catch (error) {
      console.error("Error fetching user information:", error);
      if (
        error.response &&
        (error.response.status === 400 || error.response.status === 500)
      ) {
        swal("Error", "No existe el customer", "error");
      } else {
        swal("Error", "No se pudo obtener la información del usuario", "error");
      }
      setSearchValue("");
      setUserInfo(null);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchValueChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSearchValue("");
    setUserInfo(null);
  };

  const handleFetchUserInfo = () => {
    fetchUserInfo();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleFetchUserInfo();
    }
  };

  const handleEditClick = () => {
    navigate("/update/customer", { state: { userInfo } });
  };

  const getIconForProperty = (property) => {
    switch (property.toLowerCase()) {
      case "firstname":
      case "middlename":
      case "paternalsurname":
      case "maternalsurname":
      case "customerid":
      case "softcreditclientid":
      case "clientid":
        return <PersonIcon color="primary" />;
      case "phone":
        return <PhoneIcon color="primary" />;
      case "email":
        return <EmailIcon color="primary" />;
      case "curp":
      case "clabebancariacliente":
        return <AccountBalanceIcon color="primary" />;
      case "creditLevel":
      case "pointsLevel":
        return <CreditCardIcon color="primary" />;
      default:
        return null;
    }
  };

  const renderProperty = (property, value) => {
    // Handle null or undefined values
    if (value === null || value === undefined) {
      return (
        <ListItem key={property}>
          <ListItemIcon>
            <WarningIcon color="error" />
          </ListItemIcon>
          <ListItemText
            primary={`${property}: No disponible`}
            primaryTypographyProps={{ color: "error" }}
          />
        </ListItem>
      );
    }

    // Handle nested objects
    if (typeof value === "object" && value !== null) {
      return (
        <Card key={property} sx={{ mb: 2, borderLeft: "4px solid #1976d2" }}>
          <CardHeader
            title={property}
            titleTypographyProps={{ variant: "h6", color: "primary" }}
          />
          <CardContent>
            <List>
              {Object.entries(value).map(([key, val]) =>
                renderProperty(key, val)
              )}
            </List>
          </CardContent>
        </Card>
      );
    }
    // Handle special cases like clientid, firstname, lastcontractid, etc.
    else if (property.toLowerCase() === "clientid") {
      return (
        <ListItem key={property}>
          <ListItemIcon>{getIconForProperty(property)}</ListItemIcon>
          <ListItemText
            primary={
              <>
                <strong>{property}:</strong> {value}{" "}
                <Link
                  href={`https://finnix.intelligential.tech/front-general/current/customer-file/${value}?redirect=%2Fcustomers%3Fpage%3D1&search=&customerType=&customerId=${value}&type=ASC&filter=&origin=customers`}
                  target="_blank"
                  sx={{ display: "inline-flex", alignItems: "center" }}
                >
                  Ver Cliente <LinkIcon sx={{ ml: 0.5, fontSize: 16 }} />
                </Link>
              </>
            }
          />
        </ListItem>
      );
    } else if (
      property.toLowerCase() === "firstname" &&
      user &&
      (admin.includes(user.email) || user.email === "esanchez@finnix.ai")
    ) {
      return (
        <ListItem key={property}>
          <ListItemIcon>{getIconForProperty(property)}</ListItemIcon>
          <ListItemText
            primary={
              <>
                <strong>{property}:</strong> {value}
              </>
            }
          />
          <IconButton
            color="primary"
            onClick={handleEditClick}
            size="small"
            sx={{ ml: 1 }}
          >
            <EditIcon />
          </IconButton>
        </ListItem>
      );
    } else if (property.toLowerCase() === "lastcontractid" && value) {
      return (
        <ListItem key={property}>
          <ListItemIcon>{getIconForProperty(property)}</ListItemIcon>
          <ListItemText
            primary={
              <>
                <strong>{property}:</strong> {value}{" "}
                <Link
                  href={`https://finnix.intelligential.tech/front-core/current/resume-credit/${value}`}
                  target="_blank"
                  sx={{ display: "inline-flex", alignItems: "center" }}
                >
                  Ver contrato <LinkIcon sx={{ ml: 0.5, fontSize: 16 }} />
                </Link>
              </>
            }
          />
        </ListItem>
      );
    } else if (property.toLowerCase() === "softcreditclientid" && value) {
      return (
        <ListItem key={property}>
          <ListItemIcon>{getIconForProperty(property)}</ListItemIcon>
          <ListItemText
            primary={
              <>
                <strong>{property}:</strong> {value}{" "}
                <Link
                  href={`https://softcredito.com/produccion/TRUECAPITAL/app/clientes/update/${value}`}
                  target="_blank"
                  sx={{ display: "inline-flex", alignItems: "center" }}
                >
                  Ver en SoftCrédito <LinkIcon sx={{ ml: 0.5, fontSize: 16 }} />
                </Link>
              </>
            }
          />
        </ListItem>
      );
    }
    // Default case: Handle boolean values and other types
    else {
      // Convert boolean to string for display
      const displayValue =
        typeof value === "boolean" ? value.toString() : value;

      return (
        <ListItem key={property}>
          <ListItemIcon>{getIconForProperty(property)}</ListItemIcon>
          <ListItemText
            primary={
              <>
                <strong>{property}:</strong> {displayValue}
              </>
            }
          />
          {property.toLowerCase() === "creditLevel" && value >= 4 && (
            <CheckCircleIcon color="success" sx={{ ml: 1 }} />
          )}
        </ListItem>
      );
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "linear-gradient(180deg, #f5f5f5 0%, #ffffff 100%)",
        p: 3,
      }}
    >
      {/* Barra de búsqueda fija */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "#ffffff",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
          p: 2,
          mb: 3,
          borderRadius: 2,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#ffffff",
            background: "linear-gradient(90deg, #C39BD3 0%, #9C27B0 100%)",
            p: 2,
            borderRadius: 2,
            textAlign: "center",
            mb: 2,
          }}
        >
          Información del Usuario
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Tipo de Búsqueda</InputLabel>
            <Select
              value={searchType}
              onChange={handleSearchTypeChange}
              label="Tipo de Búsqueda"
            >
              <MenuItem value="customerId">Customer ID</MenuItem>
              <MenuItem value="phone">Teléfono</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="deviceId">Device ID</MenuItem>
              <MenuItem value="intelligentialClientId">
                Client ID Intelligential
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            label={`Ingrese ${searchType}`}
            variant="outlined"
            value={searchValue}
            onChange={handleSearchValueChange}
            onKeyPress={handleKeyPress}
            fullWidth
            disabled={loading}
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1976d2",
              minWidth: 200,
              "&:hover": { backgroundColor: "#1565c0" },
            }}
            onClick={handleFetchUserInfo}
            disabled={loading || !searchValue}
          >
            {loading ? <CircularProgress size={24} /> : "Obtener Información"}
          </Button>
        </Box>
      </Box>

      {/* Contenido */}
      {userInfo ? (
        <Grid container spacing={3}>
          {Object.entries(userInfo).map(([section, properties]) => (
            <Grid item xs={12} md={6} lg={4} key={section}>
              <Card
                sx={{
                  borderRadius: 3,
                  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                  transition: "transform 0.2s",
                  "&:hover": { transform: "translateY(-5px)" },
                }}
              >
                <CardHeader
                  title={section}
                  titleTypographyProps={{ variant: "h6", color: "white" }}
                  sx={{ backgroundColor: "#9C27B0", py: 2 }}
                />
                <CardContent>
                  <List>
                    {Object.entries(properties).map(([property, value]) =>
                      renderProperty(property, value)
                    )}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography
          variant="body1"
          color="textSecondary"
          align="center"
          sx={{ mt: 5 }}
        >
          Seleccione un tipo de búsqueda e ingrese un valor para obtener la
          información del usuario.
        </Typography>
      )}
    </Box>
  );
}

export default UserInformation;
