import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import env from "../config/enviroment";
import { Typography, Box, Button, CircularProgress } from "@mui/material";

function DownloadReports() {
  const [loading, setLoading] = useState(false);

  const handleDownloadReport = async (
    method,
    endpoint,
    fileName,
    data = {}
  ) => {
    setLoading(true);
    try {
      const response = await axios({
        method,
        url: `${env.API_URL}${endpoint}`,
        headers: {
          "api-key": env.API_KEY_FABISAN,
          "Content-Type": "application/json",
        },
        responseType: "blob", // Manejar la respuesta como archivo
        data: method === "post" ? data : undefined, // Enviar datos solo si es POST
      });

      // Crear enlace de descarga
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      swal("Reporte descargado correctamente", "", "success");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "No se pudo descargar el reporte";
      swal("Error", errorMessage, "error");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" color="secondary" gutterBottom>
        Descargar Reportes
      </Typography>

      {/* <Button
        variant="contained"
        color="primary"
        onClick={() =>
          handleDownloadReport(
            "get",
            "/reports",
            "reporte_rechazados_sin_creditos.xlsx"
          )
        }
        disabled={loading}
        sx={{ mb: 2 }}
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          "Reporte de usuarios rechazados sin créditos otorgados"
        )}
      </Button> */}

      <Button
        variant="contained"
        color="secondary"
        onClick={() =>
          handleDownloadReport(
            "get",
            "/reports/reportContactsReference",
            "reporte_referencias_contacto.csv"
          )
        }
        disabled={loading}
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          "Reporte de referencias de contacto"
        )}
      </Button>
    </Box>
  );
}

export default DownloadReports;
